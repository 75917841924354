import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Make Up Day.`}</p>
    <p><strong parentName="p"><em parentName="strong">{`*`}{`Our annual 12 Days of Christmas wod will be on Christmas Eve this
year.  Classes will be at 10:30 & 12:00 and are free to all!  We’ll have
two versions of the workout, one for beginners, and will have a sign up
sheet by the white board so that each class can get done in an hour.
 We’ll be closed Christmas Day and reopen on Thursday for the 10:30
class.`}</em></strong></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      